import httpService from '../../../helpers/http.service'

export default {
    getByEnrollment(id, params) {
        return httpService.get(`enrollments/${id}/payments`, {params})
    },
    get(id = null, params) {
        return httpService.get(`payments${id ? '/' + id  : ''}`, {params})
    },
    verify(id, action) {
        return httpService.post(`payments/${id}/${action}`);
    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`payments/${id}`, params);

        }
        return httpService.post(`payments`, params);
    }
}
